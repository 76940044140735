import React, { Component } from "react";

class Header extends Component {
  state = {};
  render() {
    return (
      <div className="card-header p-0 overflow-hidden">
        <img src="/header.jpg" alt="Kaartje2go Korting Krassen" className="w-100" />
      </div>
    );
  }
}

export default Header;
