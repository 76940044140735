import React, { Component } from "react";
import Tab from "./Tab";

class TabControl extends Component {
	state = {
		tabs: [
			{ id: "ScratchBoxes", title: "Kraslot" },
			{ id: "Explanation", title: "Uitleg" },
		],
	};

	render() {
		return (
			<ul className="nav nav-pills nav-fill d-md-none mb-4">
				{this.state.tabs.map((t, i) => (
					<Tab
						onClick={() => {
							this.props.setTab(i);
						}}
						key={i}
						tab={t}
						active={this.props.tab === i}
					/>
				))}
			</ul>
		);
	}
}

export default TabControl;
