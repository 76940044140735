import React from "react";

function Explanation() {
	const explanation = [
		{
			number: "1",
			explanation: (
				<span>
					Kras de vakjes open
					<span className="d-mobile-none"> met je muntje</span>.
				</span>
			),
		},
		{
			number: "2",
			explanation: "Blijf krassen totdat de vakjes vanzelf openspringen.",
		},
		{
			number: "3",
			explanation:
				"Als je drie keer dezelfde prijs krast, dan heb je die gewonnen!",
		},
	];

	return (
		<div className="row no-gutters" style={{ color: '#555' }}>
			{explanation.map((e, i) => (
				<>
					{i ? <div className="w-100 my-2"></div> : ""}
					<div className="col-auto mr-2">
						<img
							src={`/numbers/${e.number}.png`}
							alt={`${e.number}.`}
							className="number"
						/>
					</div>
					<div className="col">{e.explanation}</div>
				</>
			))}
		</div>
	);
}

export default Explanation;
