import JSONAPIDeserializer from "jsonapi-deserializer";

class ScratchcardService {
  url = process.env.REACT_APP_API_URL;

  open = (token, resolve, reject) =>
    this.submit(token, "open", resolve, reject);

  reveal = (token, resolve, reject) =>
    this.submit(token, "reveal", resolve, reject);

  submit = (token, type, resolve, reject) => {
    const retry = () => {
      this.submit(token, type, resolve, reject);
    };

    if (!token) {
      typeof reject === "function" &&
        reject(
          {
            title: "Niet gevonden",
            details:
              "We kunnen je kraslot niet vinden...  Weet je zeker dat je de juiste link hebt gevolgd?",
          },
          retry
        );
      return;
    }

    let doReject = false;

    fetch(
      `${this.url}/api/scratch/${type}/${token}?include=boxes,campaign.prizes,coupon.prize`,
      { method: "post" }
    )
      .then((response) => {
        if (!response.ok) {
          doReject = true;
        }

        return response.json();
      })
      .then((json) => {
        if (json.errors && json.errors.length) {
          typeof reject === "function" && reject(json.errors, retry);
          return;
        }

        const data = JSONAPIDeserializer.deserialize(json);

        data.boxes = data.boxes.map((b) => {
          b.startedScratching = !!data.revealed;
          return b;
        });

        doReject
          ? typeof reject === "function" && reject(data, retry)
          : typeof resolve === "function" && resolve(data);
      })
      .catch((err) => {
        typeof reject === "function" && reject(err, retry);
      });
  };
}
// eslint-disable-next-line
export default new ScratchcardService();
