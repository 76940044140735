import React, { Component } from "react";

import ScratchBoxes from "./ScratchBoxes";
import Explanation from "./Explanation";

class TabContent extends Component {
	render() {
		const content = [
			{
				content: (
					<ScratchBoxes
						scratchcard={this.props.scratchcard}
						onScratched={this.props.onScratched}
						onStartedScratching={this.props.onStartedScratching}
					/>
				),
				classes: "col-md",
			},
			{
				content: (
					<>
						<h5 className="d-none d-md-block">Hoe werkt het?</h5>
						<Explanation />
					</>
				),
				classes: "col-md-4",
			},
		];

		return (
			<div className="tab-content row">
				{content.map((c, i) => (
					<div
						key={i}
						className={`tab-pane fade d-md-block ${c.classes} ${
							this.props.tab === i ? "active show" : ""
							}`}
					>
						{c.content}
					</div>
				))}
			</div>
		);
	}
}

export default TabContent;
