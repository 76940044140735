import React, { Component } from "react";
import TabControl from "./TabControl";
import TabContent from "./TabContent";
import Header from "./Header";

class Container extends Component {
	state = {
		tab: 0,
	};

	setTab = (index) => {
		this.setState({ tab: index });
	};

	render() {
		return (
			<div className="card shadow">
				<Header scratchcard={this.props.scratchcard} />
				<div className="card-body">
					<TabControl tab={this.state.tab} setTab={this.setTab} />
					<TabContent
						tab={this.state.tab}
						scratchcard={this.props.scratchcard}
						onScratched={this.props.onScratched}
						onStartedScratching={this.props.onStartedScratching}
					/>
				</div>
			</div>
		);
	}
}

export default Container;
