import React, { Component } from "react";

class Tab extends Component {
	state = {};
	render() {
		return (
			<li className="nav-item px-3">
				<a
					href="/#"
					onClick={this.props.onClick}
					className={
						"nav-link" + (this.props.active ? " active" : "")
					}
				>
					{this.props.tab.title}
				</a>
			</li>
		);
	}
}

export default Tab;
