import React, { Component } from "react";

class LostModal extends Component {
  state = {};

  render() {
    return (
      <div className="text-center default-font">
        <div className="m-4">
          {this.props.initialReveal ? (
            <>
              <h3>Dit kraslot heb je al opengekrast</h3>
              <p>En je had toen helaas niets gewonnen. Volgende keer beter!</p>
            </>
          ) : (
            <>
              <h3>Helaas.. Je hebt niets gewonnen.</h3>
              <p>Volgende keer beter!</p>
            </>
          )}
        </div>
        <img
          className="w-100"
          src="https://images.kaartje2go.nl/k2go-html5thumbs-fra/478xautox85/kerstkaart-dikke-knuffel-bij-ziekte-verlies-of-verdriet/kerstkaart-dikke-knuffel-bij-ziekte-verlies-of-verdriet.jpg.webp,hash=5b42bf4cc13d520f0cc12d35652aa0a8"
          alt=""
        ></img>
        <div className="m-4">
          <a href="https://kaartje2go.nl" className="button--primary">
            Terug naar de winkel
          </a>
        </div>
      </div>
    );
  }
}

class WonModal extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  copyCodeToClipboard = () => {
    const ip = this.input.current;
    ip.select();
    document.execCommand("copy");
  };

  render() {
    const { coupon } = this.props.scratchcard;

    return (
      <div className="text-center default-font p-5">
        <div>
          <h3>Gefeliciteerd!</h3>
          <p>
            {this.props.initialReveal
              ? "Dit kraslot heb je al opengekrast. Je had toen "
              : "Je hebt "}
            <b>{coupon.prize.title}</b> gewonnen!
          </p>
        </div>
        <div>
          <div className="input-group input-group-lg mb-3">
            <input
              type="text"
              id="discountCode"
              className="form-control"
              style={{ cursor: "pointer" }}
              ref={this.input}
              value={coupon.code}
              readOnly
              size="18"
              onClick={this.copyCodeToClipboard}
            />
            <div className="input-group-append d-none d-sm-block">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={this.copyCodeToClipboard}
              >
                Kopieer
              </button>
            </div>
          </div>
        </div>
        <div>
          <a
            href={`https://kaartje2go.nl?coupon=${coupon.code}`}
            className="button--primary w-100"
            target="_blank"
            rel="noopener noreferrer"
          >
            Direct gebruiken!
          </a>
        </div>
        {coupon.prize.conditions && (<div className="text-small mt-3">
              {coupon.prize.conditions}
        </div>)}
      </div>
    );
  }
}

export { WonModal, LostModal };
