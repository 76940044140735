import React, { Component } from "react";
import ScratchBox from "./ScratchBox";
import Coin from "./Coin";

class ScratchBoxes extends Component {
  constructor(props) {
    super(props);
    this.coin = React.createRef();
  }

  velocity = {
    x: 0,
    y: 0,
    t: new Date(),
  };

  moveCoin = (e) => {
    const coin = this.coin.current.coin.current;

    coin.style.left = e.clientX + "px";
    coin.style.top = e.clientY - 32 + "px";

    const t = new Date();
    const deltaT = t - this.velocity.t;
    const velocityX = (e.clientX - this.velocity.x) / deltaT;
    const velocityY = (e.clientY - this.velocity.y) / deltaT;

    this.velocity.x = e.clientX;
    this.velocity.y = e.clientY;
    this.velocity.t = t;

    coin.style.transform =
      "rotate(" +
      (12.5 +
        10 *
          Math.min(
            Math.sqrt(Math.pow(velocityX, 2) + Math.pow(velocityY, 2)),
            0.5
          ) *
          (velocityY > velocityX ? 1 : -1)) +
      "deg)";

    const scale = (e.clientX / document.body.offsetWidth) * 0.3 + 0.7;
    [
      this.coin.current.foreground.current,
      this.coin.current.background.current,
    ].forEach((c) => {
      c.style.transform = "scaleX(" + scale + ")";
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row" onMouseMove={this.moveCoin}>
          {this.props.scratchcard.boxes.map((box, i) => {
            const br = "w-100" + (i % 2 ? "" : " d-sm-none");
            return (
              <React.Fragment key={i}>
                <div className="col mb-4">
                  <ScratchBox
                    scratchcard={this.props.scratchcard}
                    box={box}
                    coin={this.coin}
                    onScratched={this.props.onScratched}
                    onStartedScratching={this.props.onStartedScratching}
                  />
                </div>
                <div className={br}></div>
              </React.Fragment>
            );
          })}
        </div>
        <Coin ref={this.coin} />
      </React.Fragment>
    );
  }
}

export default ScratchBoxes;
