import React, { Component } from "react";

import Container from "./components/Container";
import { WonModal, LostModal } from "./components/Modals";
import ConfettiService from "./services/ConfettiService";
import ScratchcardService from "./services/ScratchcardService";
import Explanation from "./components/Explanation";

import { CircularProgress } from "@rmwc/circular-progress";
import { DialogQueue, createDialogQueue } from "@rmwc/dialog";
import { SnackbarQueue, createSnackbarQueue } from "@rmwc/snackbar";

import "@rmwc/circular-progress/styles";
import "@rmwc/snackbar/styles";
import "@rmwc/dialog/styles";
const { dialogs, alert } = createDialogQueue();
const { messages, notify } = createSnackbarQueue();

class App extends Component {
  state = {};

  token = null;
  explanationShown = false;

  constructor(props) {
    super(props);
    this.token = (new URL(window.location).pathname.split("/")[1] || "").trim();
  }

  checkWonLost = () => {
    if (this.state.scratchcard.revealed) {
      if (this.state.scratchcard.coupon) {
        new ConfettiService({
          length: 200,
          duration: 8000,
        });
        this.fireAlert({
          title: null,
          body: (
            <WonModal
              scratchcard={this.state.scratchcard}
              initialReveal={this.state.initialReveal}
            />
          ),
          preventOutsideDismiss: true,
          acceptLabel: null,
        });
      } else {
        this.fireAlert({
          title: null,
          body: (
            <LostModal
              scratchcard={this.state.scratchcard}
              initialReveal={this.state.initialReveal}
            />
          ),
          preventOutsideDismiss: true,
          acceptLabel: null,
        });
      }
    }
  };

  fireAlert = (data, cb) =>
    alert(data).then((res) => typeof cb === "function" && cb(res));

  fireError = (err, retry) => {
    if (Array.isArray(err)) {
      err = err[0];
    }

    if (typeof err !== "object") {
      err = {};
    }

    this.fireAlert(
      {
        title: err.title || "Er is iets mis gegaan",
        body:
          err.details ||
          "Tijdens het ophalen van je kraslot is iets mis gegaan. Neem contact op met de klantenservice.",
        acceptLabel: "Probeer opnieuw",
      },
      retry
    );
  };

  handleScratched = (box) => {
    if (box) {
      box.opened = true;

      const won =
        this.state.scratchcard.boxes.filter((b) => b.opened && b.id === box.id)
          .length >= 3;
      const lost =
        this.state.scratchcard.boxes.filter((b) => b.opened).length >= 6;

      if (won || lost) {
        this.cancelNotify = true;
        clearTimeout(this.scratchNotice);
        ScratchcardService.reveal(
          this.token,
          (json) => {
            json.boxes.forEach((b) => (b.opened = true));
            this.setState({ scratchcard: json });
          },
          this.fireError
        );
      }

      this.setState({ scratchcard: this.state.scratchcard });
    }
  };

  handleStartedScratching = (box) => {
    const { scratchcard } = this.state;

    if (box) {
      if (!box.startedScratching) {
        box.startedScratching = true;
        this.setState({ scratchcard: scratchcard });
      }

      clearTimeout(this.scratchNotice);
      if (
        !scratchcard.revealed &&
        !this.cancelNotify &&
        !scratchcard.boxes.every((b) => b.opened) &&
        (scratchcard.boxes.every((b) => b.startedScratching) ||
          scratchcard.boxes
            .reduce(
              (a, b) => {
                b.startedScratching && a.find((t) => b.id === t.id).count++;
                return a;
              },
              scratchcard.campaign.prizes.map((p) => ({ id: p.id, count: 0 }))
            )
            .some((t) => t.count > 2))
      ) {
        // Amount of ms to wait before sending the customer a notice so scratch more off
        this.scratchNotice = setTimeout(() => {
          this.cancelNotify = true;
          notify({
            title: <b>Tip!</b>,
            body: "Blijf krassen totdat de vakjes openspringen!",
            timeout: -1,
            dismissesOnAction: true,
            actions: [
              {
                title: "Sluiten",
              },
            ],
          });
        }, 5000);
      }
    }
  };

  componentDidMount = () => {
    ScratchcardService.open(
      this.token,
      (json) => {
        this.setState({ scratchcard: json, initialReveal: json.revealed });
      },
      this.fireError
    );
  };

  componentDidUpdate = () => {
    this.checkWonLost();
  };

  render() {
    const { scratchcard } = this.state;

    if (!scratchcard) {
      if (!this.token) {
        this.fireAlert({
          title: "Niet gevonden",
          body:
            "We kunnen je kraslot niet vinden...  Weet je zeker dat je de juiste link hebt gevolgd?",
          acceptLabel: null,
          preventOutsideDismiss: true,
        });
      }

      return (
        <>
          <div className="app-loading-shim">
            <CircularProgress size={72} />
          </div>
          <DialogQueue dialogs={dialogs} />
        </>
      );
    }

    document.title = scratchcard.campaign.title + " | Kaartje2go";

    if (!scratchcard.revealed && !this.explanationShown) {
      this.explanationShown = true;

      this.fireAlert({
        title: "Korte uitleg",
        body: (
          <>
            {scratchcard.campaign.description && (
              <p>{scratchcard.campaign.description}</p>
            )}
            <Explanation />
          </>
        ),
        acceptLabel: "Ik snap het",
      });
    }

    return (
      <>
        <main role="main">
          <div className="container p-3">
            <Container
              scratchcard={scratchcard}
              onScratched={this.handleScratched}
              onStartedScratching={this.handleStartedScratching}
            />
            {/* Put <Conditions /> here in a future update */}
          </div>
        </main>
        <DialogQueue dialogs={dialogs} />
        <SnackbarQueue messages={messages} />
      </>
    );
  }
}

export default App;
