import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ThemeProvider } from "@rmwc/theme";
import "@rmwc/theme/styles";

import "./index.scss";

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider
			options={{
				primary: "#00b8ff",
			}}
		>
			<App />
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
