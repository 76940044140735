import React, { Component } from "react";
import Scratchie from "scratchie";

import "./scratch.scss";

class ScratchBox extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.scratch = React.createRef();
    this.important = React.createRef();
  }

  componentDidMount() {
    var _this = this;
    new Scratchie(this.scratch.current, {
      brush: "/brush.png",
      image: "/scratch.png",
      onRenderEnd: function () {
        if (!_this.props.scratchcard.revealed) {
          this.element.classList.remove("scratchie--scratched");
        }
        // Show the form when Image is loaded.
        // this.element.querySelector(".scratchie__secret").style.visibility = "visible";
      },
      onScratch: function (scratchedPixels) {
        const importantPixels = this.getScratchedPixels(
          _this.getArea(_this.important.current, 2)
        );

        if (scratchedPixels >= 1) {
          _this.props.onStartedScratching(_this.props.box);
        }

        if (
          importantPixels >= 90 ||
          (scratchedPixels >= 60 && importantPixels >= 80)
        ) {
          !this.scratched && _this.onScratched();
          this.scratched = true;
        }
      },
    });
  }

  getArea(element, offset = 0) {
    if (element instanceof HTMLElement) {
      return {
        x: element.offsetLeft - offset,
        y: element.offsetTop - offset,
        w: element.offsetWidth + offset * 2,
        h: element.offsetHeight + offset * 2,
      };
    }
  }

  onScratched() {
    this.hideCoin();
    this.props.onScratched(this.props.box);
  }

  showCoin = () => {
    if (
      window.matchMedia("(pointer:fine)").matches &&
      !this.scratch.current.scratchie.scratched
    ) {
      this.props.coin.current.coin.current.classList.add("show", "active");
    } else {
      this.hideCoin();
    }
  };

  hideCoin = () => {
    this.props.coin.current.coin.current.classList.remove("show", "active");
  };

  render() {
    return (
      <div className="card overflow-hidden">
        <div
          className={
            "scratchie d-flex" +
            (this.props.box.opened || this.props.scratchcard.revealed
              ? " scratchie--scratched"
              : "")
          }
          ref={this.scratch}
          onMouseEnter={this.showCoin}
          onMouseLeave={this.hideCoin}
        >
          <div className="scratchie__secret m-auto">
            <h1 className="text-center" ref={this.important}>
              {
                (
                  this.props.scratchcard.campaign.prizes.find(
                    (p) => p.id === this.props.box.id
                  ) || { title: "Geen prijs" }
                ).title
              }
            </h1>
          </div>
        </div>
      </div>
    );
  }
}

export default ScratchBox;
