// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/tile.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scratchie {\n  position: relative;\n  -webkit-user-select: none;\n          user-select: none;\n  cursor: none;\n  user-select: none;\n  min-height: 200px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover; }\n  .scratchie canvas {\n    position: absolute;\n    top: 0;\n    left: 0;\n    transition-duration: 0.5s;\n    transition-property: opacity, transform;\n    opacity: 1;\n    transform: scale(1); }\n\n.scratchie.scratchie--scratched {\n  cursor: default; }\n  .scratchie.scratchie--scratched canvas {\n    pointer-events: none;\n    opacity: 0;\n    transform: scale(1.5); }\n", "",{"version":3,"sources":["webpack://./src/components/scratch.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAiB;UAAjB,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,yDAA4C;EAC5C,sBAAsB,EAAA;EAPxB;IAUI,kBAAkB;IAClB,MAAM;IACN,OAAO;IAEP,yBAAyB;IACzB,uCAAuC;IACvC,UAAU;IACV,mBAAmB,EAAA;;AAIvB;EACE,eAAe,EAAA;EADjB;IAII,oBAAoB;IACpB,UAAU;IACV,qBAAqB,EAAA","sourcesContent":[".scratchie {\n  position: relative;\n  user-select: none;\n  cursor: none;\n  user-select: none;\n  min-height: 200px;\n  background-image: url(../../public/tile.jpg);\n  background-size: cover;\n\n  canvas {\n    position: absolute;\n    top: 0;\n    left: 0;\n\n    transition-duration: 0.5s;\n    transition-property: opacity, transform;\n    opacity: 1;\n    transform: scale(1);\n  }\n}\n\n.scratchie.scratchie--scratched {\n  cursor: default;\n\n  canvas {\n    pointer-events: none;\n    opacity: 0;\n    transform: scale(1.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
